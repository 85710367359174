import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaVolumeUp, FaVolumeMute, FaPlay } from "react-icons/fa";
import io from "socket.io-client";
import backgroundImage from "../assets/2023-06-30_16.24.09.png";
import logo from "../assets/0362e60c-548e-457c-aaab-d4e7934b9340.png";

// Function to decode JWT token
const decodeToken = (token) => {
  try {
    const base64 = token.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
            .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

const AudioPlayer = () => {
  const navigate = useNavigate();
  const {token} = useParams();
  const [playerData, setPlayerData] = useState({});
  const [volume, setVolume] = useState(1); // State for audio volume
  const [error, setError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false)
  const socketRef = useRef(null);
  const audioContextRef = useRef(null);
  const currentAudioRef = useRef(null);
  const fadeDuration = 2; // Duration in seconds for fade in/out

  useEffect(() => {
    if (token) {
      try {
        const data = decodeToken(token);
        if (!data.ONLINE) {
          setError("Vous n'êtes pas en ligne !");
        } else {
          setPlayerData(data);
        }
      } catch (e) {
        console.error("Token Erroné", e);
        setError("Votre Token n'est pas valide. Veuillez vous reconnecter !");
      }
    }

  }, [token]);

  const initSocket = () => {
    const playerUUID = playerData.UUID;
    socketRef.current = io('https://backend.novus-odyssey.fr', {
      query: { uuid: playerUUID },
    });

    socketRef.current.on('minecraftEvent', (data) => {
      const jsonData = JSON.parse(data);
      console.log(jsonData);

      if (jsonData.AUDIOACTION === "PlayerExitAudioRegion") {
        stopCurrentAudio();
        jsonData.AUDIOAREANAME = "null";
      }

      if (jsonData.AUDIOACTION === "PlayerEnterAudioRegion" || jsonData.AUDIOACTION === "PlayerChangeAudioRegion") {
        switchZone(jsonData.AUDIOAREANAME);
      }

      const mergedData = { ...jsonData };
      setPlayerData(mergedData);
    });
  };

  // Cleanup the socket connection
  const cleanupSocket = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }
  };



  // Function to send a message to the server
  const sendMessage = (data) => {
    socketRef.current.emit('messageFromClient', data);
  };

  // Function to initialize the audio context
  const initAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
  };

  // Function to play audio with fade in
  const playAudioWithFadeIn = (audioUrl) => {
    initAudioContext();
    const audio = new Audio(audioUrl);
    const track = audioContextRef.current.createMediaElementSource(audio);
    const gainNode = audioContextRef.current.createGain();
    track.connect(gainNode).connect(audioContextRef.current.destination);

    gainNode.gain.setValueAtTime(0, audioContextRef.current.currentTime); // Start with volume at 0
    gainNode.gain.linearRampToValueAtTime(1, audioContextRef.current.currentTime + fadeDuration); // Gradually increase volume

    audio.loop = true;
    audio.play();
    currentAudioRef.current = {audio, gainNode};
  };

  // Function to stop current audio with fade out
  const stopCurrentAudio = () => {
    if (currentAudioRef.current) {
      const {audio, gainNode} = currentAudioRef.current;

      // Fade volume to 0
      gainNode.gain.linearRampToValueAtTime(0, audioContextRef.current.currentTime + fadeDuration);

      // Stop the audio after fade duration
      setTimeout(() => {
        audio.pause();
        audio.currentTime = 0; // Reset to the beginning
      }, fadeDuration * 1000);
    }
  };

  // Function to switch audio zone
  const switchZone = (newAudioAreaName) => {
    stopCurrentAudio(); // Stop current audio with fade out
    const audioUrl = `https://audio.novus-odyssey.fr/Audio/${newAudioAreaName}.ogg`;
    playAudioWithFadeIn(audioUrl); // Play new audio with fade in
  };

  // Volume control functions
  const adjustVolume = (volumeValue) => {
    setVolume(volumeValue);
    if (currentAudioRef.current) {
      const {gainNode} = currentAudioRef.current;
      gainNode.gain.setValueAtTime(volumeValue, audioContextRef.current.currentTime); // Adjust volume
    }
  };

  const handleVolumeChange = (e) => {
    const volumeValue = Number(e.target.value) / 100; // Convert to proportion
    adjustVolume(volumeValue);
  };

  const handleMute = () => {
    const targetVolume = volume > 0 ? 0 : 1; // Toggle between mute and max volume
    adjustVolume(targetVolume);
  };

  const handleCloseSession = () => {
    setIsModalOpen(true);
  };

  const confirmCloseSession = () => {
    cleanupSocket();
    navigate("/disconnect");
  };

  const cancelCloseSession = () => {
    setIsModalOpen(false);
  };

  // Handle play button click
  const handlePlay = () => {
    setShowPlayer(true);
    initSocket();
    sendMessage({UUID: playerData.UUID, STATUS: 'USER_CONNECTED'});
  };

  const HTML_audioPlayer = <div
      className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg shadow-2xl p-8 max-w-md mx-auto text-center text-white">
    <h1 className="uppercase text-3xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#f2a206] to-[#d75151]">Novus
                                                                                                                                 Odyssey
                                                                                                                                 Audio</h1>
    <div className="player-info mb-8">
      <div className="relative w-40 h-40 mx-auto mb-4">
        <img id="playerSkin" className="w-full h-full rounded-lg shadow-lg border-2 border-gray-700"
             src={`https://mc-heads.net/avatar/${playerData.UUID}`} alt="Skin du joueur"/>
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-lg"></div>
      </div>
      <p className="text-gray-300 mb-1">
        UUID: <span className="font-semibold">{playerData.UUID}</span>
      </p>
      <p className="text-gray-300">
        Zone: <span className="font-semibold">{playerData.AUDIOAREANAME}</span>
      </p>
    </div>

    <div className="relative mb-8">
      <p className="absolute left-0 -top-6 text-gray-400">Volume:</p>
      <input
          type="range"
          id="volumeSlider"
          min="0"
          max="100"
          value={volume * 100} // Convert to percentage for the slider
          onChange={handleVolumeChange}
          className="w-full h-2 bg-gray-700 rounded-lg cursor-pointer"
      />
      <p className="absolute right-0 -top-6 text-gray-400">{Math.round(volume * 100)}%</p>
    </div>

    <div className="controls flex items-center justify-center mb-4">
      <button onClick={handleMute}
              className={`py-3 px-6 rounded-full text-sm font-semibold transition-all duration-300 flex items-center justify-center ${volume > 0 ? "bg-red-500 hover:bg-red-600" : "bg-green-500 hover:bg-green-600"}`}>
        {volume > 0 ? (
            <span className="flex items-center">
                      <FaVolumeMute className="mr-2"/> Couper le son
                    </span>
        ) : (
            <span className="flex items-center">
                      <FaVolumeUp className="mr-2"/> Activer le son
                    </span>
        )}
      </button>
    </div>


  </div>
  return (
      <section className="flex flex-col justify-between h-screen bg-gray-800 text-white"
               style={{backgroundImage: `url(${backgroundImage})`}}>
        <header className="flex items-center justify-between w-full p-4">
          <img src={logo} alt="logo Novus" className="w-20"/>
          <button onClick={handleCloseSession}
                  className="bg-red-500 hover:bg-red-600 font-semibold transition-bg duration-500 text-white py-2 px-4 rounded">
            Déconnexion
          </button>
        </header>

        <main className="flex items-center justify-center flex-grow w-full p-4">
          {token ? (
              !error ? (

                  !showPlayer ?<div className="h-52 w-52 bg-gray-800 shadow-2xl flex items-center justify-center">
                    <button
                        onClick={handlePlay}
                        className="bg-green-500 hover:bg-green-600 py-3 px-6 rounded-full text-white font-semibold flex items-center justify-center"
                    >
                      <FaPlay className="mr-2"/> Jouer
                    </button>
                  </div>
                      : HTML_audioPlayer
              ) : (
                  <div className="bg-red-500 p-4 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Erreur</h2>
                    <p>{error}</p>
                  </div>
              )
          ) : (
              <p>Chargement...</p>
          )}
        </main>

        {/* Modal for disconnect confirmation */}
        {isModalOpen && (
            <div className="fixed inset-0 bg-black/75 flex items-center justify-center">
              <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-xs mx-auto">
                <h2 className="text-xl font-semibold text-white mb-4">Confirmer la déconnexion</h2>
                <div className="flex items-center justify-between">
                  <button onClick={confirmCloseSession}
                          className="bg-red-500 hover:bg-red-600 py-2 px-4 rounded text-white font-semibold">Déconnexion
                  </button>
                  <button onClick={cancelCloseSession}
                          className="bg-gray-500 hover:bg-gray-600 py-2 px-4 rounded text-white font-semibold">Annuler
                  </button>
                </div>
              </div>
            </div>
        )}
      </section>
  );
};

export default AudioPlayer;
