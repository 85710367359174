import { Route, Routes } from "react-router-dom";
import Audio from "./Page/Audio";
import UnConnect from "./Page/unConnect";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Audio />}>
        <Route path=":token" element={<Audio />} />
      </Route>
      <Route path="/disconnect" element={<UnConnect />} />
    </Routes>
  );
}

export default App;
