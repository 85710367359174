import React from "react";
import backgroundImage from "../assets/2023-06-30_16.24.09.png";
import logo from "../assets/0362e60c-548e-457c-aaab-d4e7934b9340.png";
import { Link } from "react-router-dom";

const unConnectedPage = () => {
  return (
    <section
      className="flex flex-col justify-between h-screen bg-gray-800 text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <header className="flex items-center justify-between w-full p-4">
        <img src={logo} alt="logo Novus" className=" w-20" />
      </header>

      <main className="flex items-center justify-center flex-grow w-full p-4">
        <div className="text-center bg-gray-900 bg-opacity-75 p-8 rounded-lg">
          <h1 className="text-2xl font-bold mb-4">Déconnexion</h1>
          <p className="mb-4 underline">
            Vous avez été déconnecté avec succès.
          </p>
          <p className="m-4 font-bold uppercase ">
            Vous pouvez maintenant fermer cette onglet.
          </p>
        </div>
      </main>

      <footer className=" flex items-center justify-end w-full p-4">
        <p className=" uppercase font-bold">Novus odyssey</p>
      </footer>
    </section>
  );
};

export default unConnectedPage;
